.Navbar {
  a {
    &,
    &:focus,
    &:active,
    &:visited {
      color: inherit;
    }
  }
}
