$light-color: #f8fcfd;
$disabled-text-color: #aaaaaa;
$disabled-border-color: #d2d2d2;
$dark-color: #131313;

// Font families
$roboto: 'Roboto', 'Helvetica', 'Arial', sans-serif;

// Breakpoints
$screen-xs: 400px;
$screen-sm: 600px;
$screen-md: 900px;
$screen-lg: 1280px;
$screen-xl: 1920px;
