@import './variables';

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background: $light-color;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

* {
  box-sizing: border-box;
}

p {
  font-size: 1rem;
}

a {
  &:active {
    text-decoration: none;
    color: inherit;
  }
}

.nowrap.nowrap {
  white-space: nowrap;
}

.pre-wrap.pre-wrap {
  white-space: pre-wrap;
}

.text-center.text-center {
  text-align: center;
}

.text-right.text-right {
  text-align: right;
}

.text-left.text-left {
  text-align: left;
}

.margin-right-1.margin-right-1 {
  margin-right: 1rem;
}

.edit-container.edit-container {
  width: calc(23rem + 2rem + 48px);
}

.App-Component-wrapper.App-Component-wrapper {
  width: calc(100% - 260px);
  padding-top: 83px;
}

.App-Component-wrapper.App-Component-wrapper > div {
  padding-bottom: 10rem;
}

.MuiContainer-root.MuiContainer-root {
  padding-top: 0.5px; // will fix negative margin inside child nodes
  padding-bottom: 0.5px;
}

.MuiFormLabel-root.MuiFormLabel-root.MuiFormLabel-root {
  background: $light-color;
  padding-right: 0.33rem;
}

.Card + .Card {
  margin-top: 100px;
}

.action-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > *:first-child {
    flex-grow: 100;
    margin-right: 1rem;
  }
}

.mr-1 {
  margin-right: 4px !important;
}

.status-buttons {
  flex-grow: 10;
  text-align: right;

  button {
    margin-left: 15px;
  }
}

.form-group-title {
  display: block;
  margin: 10px 0 -10px 0;
  color: #aaaaaa !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.42857;
  letter-spacing: unset;
}

.address-group {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 15px;
  margin: 20px 0 0;

  .MuiTypography-subtitle1 {
    display: table;
    width: auto;
    margin: -30px 0 15px -10px;
    padding: 0 10px;
    background-color: #fff;
  }
}

.MuiAutocomplete-tag {
  height: 27px !important;
}

.ProductQualityIssues {
  &-item {
    margin: 0 0 20px 0;

    &:last-child {
      margin: 0;
    }
  }

  &-reason {
    margin: 0 0 5px 0;
  }

  &-reprint {
    font-weight: bold;
  }
}
